.tagInputClasses {
  min-width: 250px;
  height: 50px;
}

.tagInputFieldClass {
  min-width: 250px;
  height: 50px;
  margin-bottom: 20px;
}

.selectedClass {
  margin-top: 20px;
  min-width: 250px;
}

.removeClass {
  margin: 5px;
}

.tagClass {
  background: #f5fbf5;
  margin: 2px;
}