@media print {
  @page {
    size: auto;
    margin: 0mm;
  }
  html, body {
    width: "100%";
    height: "100%";
    max-width: "100%";
    max-height: "100%";
    padding: 0;
    margin-top: 15mm;
  }
}

