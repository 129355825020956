@media print {
  @page {   
    size: auto;
    /* size: 600mm 15mm; 
    margin-top: 5mm;
    margin-left: 4mm; */
  }
  html, body {  
    padding: 0;
  }
  .printC {
      page-break-inside: avoid;
      page-break-after: auto;
      padding: 0;
      gap: 4mm;   
      margin-left: 5mm;
      margin-top: 1.2mm;
      margin-bottom: 0.6mm;   
      height: 15mm;
  }
}

.printC {
  display: "flex";
  align-items: "center";
  gap: "15px";
}
span {
  font-size: "12px";
}
/* 
@media print {
  @page {
    size: 100mm 15mm;
    page-break-after: always;
    margin-top: 3mm;
    margin-left: 3mm;
    padding: 0;
  }
  html, body {
    width: "100%";
    height: "100%";
    max-width: "100%";
    max-height: "100%";
    padding: 0;
    margin: 0;
  }
  .printC {
      page-break-inside: avoid;
      page-break-after: auto;
      gap: 4mm
  }
}

.printC {
  display: "flex";
  align-items: "center";
  gap: "15px";
}
span {
  font-size: "12px";
} */